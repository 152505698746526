<template>
  <section>
    <h6 class="mb-2">{{ $t('general.search_filters') }}</h6>
    <div class="row align-items-center mt-3">
      <b-colxx lg="6" md="6" sm="12" xxs="12">
        <label>Intervalo</label>
        <v-select
          v-model="interval"
          :options="intervals"
          class="w-100 mt-1 select__custom"
          :reduce="(interval) => interval.value"
          placeholder="Selecciona un intervalo"
          label="text"
        >
        </v-select>
      </b-colxx>
      <b-colxx lg="6" md="6" sm="6" xxs="12" v-if="!interval"> </b-colxx>
      <b-colxx lg="6" md="6" sm="6" xxs="12" v-if="interval === 'dayMonth'">
        <label class="w-100">
          Día:
          <b-form-datepicker
            label-no-date-selected="Selecciona una fecha"
            :max="maxDateToday"
            v-model="date"
            class="py-1 d-flex"
          ></b-form-datepicker>
        </label>
      </b-colxx>
      <b-colxx lg="6" md="6" sm="6" xxs="12" v-if="interval == 'day'">
        <label class="w-100">
          Mes:
          <v-select
            v-model="monthSelected"
            :options="lastMonths"
            class="w-100 mt-1 select__custom"
            :reduce="(month) => month.value"
            placeholder="Selecciona un mes"
            label="text"
          >
          </v-select>
        </label>
      </b-colxx>
      <b-colxx lg="6" md="6" sm="6" xxs="12" v-if="interval == 'month'">
        <label class="w-100">
          Año:
          <v-select
            v-model="yearSelected"
            :options="lastTwoYears"
            class="w-100 mt-1 select__custom"
            :reduce="(month) => month.value"
            placeholder="Selecciona un mes"
            label="text"
          >
          </v-select>
        </label>
      </b-colxx>
      <b-col lg="3" md="3" sm="6" xxs="12" v-if="interval == 'range'">
        <label>Desde: </label>
        <b-form-datepicker
          label-no-date-selected="Selecciona una fecha"
          v-model="date_from"
          class="mb-2 d-flex"
          :max="maxDateToday"
        ></b-form-datepicker>
      </b-col>
      <b-col lg="3" md="3" sm="6" xxs="12" v-if="interval == 'range'">
        <label for="example-datepicker" class="">Hasta: </label>
        <b-form-datepicker
          label-no-date-selected="Selecciona una fecha"
          :max="maxDateToday"
          :min="date_from"
          v-model="date_to"
          class="mb-2 d-flex"
          :disabled="!date_from"
        ></b-form-datepicker>
      </b-col>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import EventBus from '@/bus'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    'v-select': vSelect
  },
  created() {
    EventBus.$on('moduleSelected', ({ id }) => {
      this.id = id
      let date = this.getDateFromInterval(this.interval)
      this.getDataCharts(this.interval, date)
    })
    this.setLastMonths()
  },
  data() {
    return {
      date_from: null,
      date_to: null,
      date: new Date().toISOString().split('T')[0],
      lastMonths: [],
      numberOfMonths: 12,
      monthSelected: new Date().toISOString().split('T')[0],
      yearSelected: new Date().toISOString().split('T')[0],
      intervals: [
        { text: this.$t('interval.today'), value: 'dayMonth' },
        { text: this.$t('interval.month'), value: 'day' },
        { text: this.$t('interval.year'), value: 'month' },
        { text: this.$t('interval.range'), value: 'range' },
        { text: this.$t('interval.annual'), value: 'year' }
      ],
      interval: 'dayMonth',
      id: null
    }
  },
  computed: {
    maxDateToday() {
      return new Date()
    },
    lastTwoYears() {
      return [
        {
          text: this.$moment().format('YYYY'),
          value: this.$moment().format('YYYY-MM-DD')
        },
        {
          text: this.$moment().subtract(1, 'year').format('YYYY'),
          value: this.$moment().subtract(1, 'year').format('YYYY-MM-DD')
        }
      ]
    }
  },
  methods: {
    ...mapActions({ getConsumptionChart: 'monitoring/getConsumptionChart' }),
    getDateFromInterval(interval) {
      if (interval == 'dayMonth') {
        return this.date
      } else if (interval == 'day') {
        return this.monthSelected
      } else if (interval == 'month') {
        return this.yearSelected
      } else {
        this.$moment().format('YYYY-MM-DD')
      }
    },
    setLastMonths() {
      for (let index = 0; index < this.numberOfMonths; index++) {
        let currentDate = this.$moment().subtract(index, 'month')
        this.lastMonths.push({
          text: currentDate.format('MMMM YYYY'),
          value: currentDate.format('YYYY-MM-DD')
        })
      }
    },
    resetDatePickers() {
      this.date_from = null
      this.date_to = null
    },
    //Añadir Data a la grafica
    async getDataCharts(interval, date) {
      //Por ahora solo para cuando se selecciona un inversor
      const date_init = this.date_from
      const date_end = this.date_to
      let data = await this.getConsumptionChart({
        interval,
        date_init,
        date_end,
        date: this.$moment(date),
        id: this.id
      })
      EventBus.$emit('changeDataCharts', {
        data,
        interval,
        id: this.id
      })
    }
  },
  watch: {
    async interval(newInterval) {
      if (newInterval !== 'range') {
        this.resetDatePickers()
        this.date = this.getDateFromInterval(newInterval)
        await this.getDataCharts(newInterval, this.date)
      }
    },
    async date() {
      if (this.interval === 'dayMonth') {
        await this.getDataCharts(this.interval, this.date)
      }
    },
    async monthSelected() {
      await this.getDataCharts('day', this.monthSelected)
    },
    async yearSelected() {
      await this.getDataCharts('month', this.yearSelected)
    },
    async date_from() {
      if (this.date_from && this.date_to) {
        await this.getDataCharts('range')
      }
    },
    async date_to() {
      if (this.date_from && this.date_to) {
        await this.getDataCharts('range')
      }
    }
  }
}
</script>

<style></style>
