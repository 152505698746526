<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h6 class="">{{ $t('general.energy_statistics') }}</h6>
    </div>
    <line-chart
      v-if="interval !== 'dayMonth' && dataChart.labels.length"
      :data="dataChart"
      shadow
    />
    <p v-else class="text-center mt-5">Sin datos para mostrar</p>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/Line'
import EventBus from '@/bus'

export default {
  props: {
    dataChart: {
      defautlt: () => []
    }
  },
  created() {
    EventBus.$on('changeDataCharts', ({ data, interval, id }) => {
      this.id = id
      this.interval = interval
      this.setDataChart(interval, data)
    })
  },
  data() {
    return {
      id: null,
      interval: null
    }
  },
  components: {
    LineChart
  },
  methods: {
    //Añadir Data a la grafica
    async setDataChart(interval, data) {
      this.dataChart.labels = []
      //Por ahora solo para cuando se selecciona un inversor
      this.dataChart.labels = data.map((item) =>
        interval == 'day'
          ? `${this.$moment(item.date).format('DD')}`
          : interval == 'month'
          ? `${this.$moment(item.date).format('MMM')}`
          : interval == 'range'
          ? `${this.$moment(item.date).format('DD/MM/YYYY')}`
          : `${this.$moment(item.date).format('YYYY')}`
      )
      //Calculate kw data
      this.dataChart.datasets[0].data = data.map((item) => item.energy_buy)
      this.dataChart.datasets[1].data = data.map((item) => item.energy_sell)
    },
    calculateKwData(data) {
      return data.map((item) => {
        return {
          ...item,
          energy_sell: parseFloat(item.energy_sell / 1000).toFixed(2),
          energy_buy: parseFloat(item.energy_buy / 1000).toFixed(2)
        }
      })
    }
  }
}
</script>

<style></style>
