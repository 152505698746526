<template>
  <div :class="containerClass">
    <canvas height="400px" ref="chart"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js'
import { lineChartOptions, lineChartOptionsPercentaje } from './config'

export default {
  props: {
    data: {
      type: Object
    },
    containerClass: {
      type: String,
      default: 'chart-container'
    },
    shadow: {
      type: Boolean,
      default: false
    },
    typeLabel: {
      type: String,
      default: 'kW'
    }
  },
  data() {
    return {
      chartCtx: null
    }
  },
  mounted() {
    if (this.shadow) {
      Chart.controllers.lineWithShadow = Chart.controllers.line
      Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
        draw(ease) {
          Chart.controllers.line.prototype.draw.call(this, ease)
          const chartCtx = this.chart.ctx
          chartCtx.save()
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)'
          chartCtx.shadowBlur = 10
          chartCtx.shadowOffsetX = 0
          chartCtx.shadowOffsetY = 30
          chartCtx.responsive = true
          chartCtx.stroke()
          Chart.controllers.line.prototype.draw.apply(this, arguments)
          chartCtx.restore()
        }
      })
    }
    const ctx = this.$refs.chart
    const myChart = new Chart(ctx, {
      type: this.shadow ? 'lineWithShadow' : 'line',
      data: this.data,
      options: this.typeLabel == 'kW' ? lineChartOptions : lineChartOptionsPercentaje
    })
    this.chartCtx = myChart
  },
  watch: {
    'data.labels': {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.chartCtx.update()
        }, 500)
      }
    }
  }
}
</script>
