var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-energy"},[_c('b-img',{attrs:{"height":"400","width":"430","src":_vm.getImageEnergyIfGeneratorIsActive.img,"fluid":"","alt":"Responsive image"}}),_c('div',{staticClass:"top"},[_vm._v(_vm._s(_vm.getCalculeKw(_vm.pvValue)))]),(_vm.consumption !== null)?_c('div',{staticClass:"centered"},[_vm._v(_vm._s(_vm.getCalculeKw(_vm.consumption)))]):_vm._e(),(_vm.network !== null)?_c('div',{staticClass:"bottom-right"},[_vm._v(" "+_vm._s(_vm.getCalculeKw(Math.abs(_vm.network)))+" ")]):_vm._e(),(_vm.battery !== null || _vm.generator !== null)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getImageEnergyIfGeneratorIsActive.generator),expression:"getImageEnergyIfGeneratorIsActive.generator"}],staticClass:"bottom-left"},[_vm._v(" "+_vm._s(_vm.getCalculeKw(_vm.brandIsGoodwe ? _vm.battery : _vm.generator))+" ")]):_vm._e(),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.ifPvGreatherThanComsumption && _vm.pvValue !== 0 && _vm.network !== 0),expression:"ifPvGreatherThanComsumption && pvValue !== 0 && network !== 0"}],staticClass:"iconsmind iconsminds-arrow-to arrow-big arrow-diagonal flicker-row"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ifPvGreatherThanComsumption && _vm.network !== 0 && _vm.network !== null),expression:"!ifPvGreatherThanComsumption && network !== 0 && network !== null"}],staticClass:"iconsmind iconsminds-up-3 arrow arrow-left move-diagonal-left"}),(
      (_vm.getImageEnergyIfGeneratorIsActive.generator || !_vm.brandIsGoodwe) &&
      _vm.battery &&
      _vm.battery < 0 &&
      _vm.battery !== null
    )?_c('i',{staticClass:"iconsmind iconsminds-up-3 arrow arrow-right move-diagonal-right-invested"}):_vm._e(),(
      (_vm.getImageEnergyIfGeneratorIsActive.generator || !_vm.brandIsGoodwe) &&
      _vm.battery &&
      _vm.battery > 0 &&
      _vm.battery !== null
    )?_c('i',{staticClass:"iconsmind iconsminds-up-3 arrow arrow-right move-diagonal-right"}):_vm._e(),(_vm.pvValue !== 0)?_c('i',{staticClass:"iconsmind iconsminds-down-3 arrow arrow-dowm move-vertical"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }