<template>
  <b-card class="mb-2">
    <h4 class="text-center mt-4">
      Bienvenido a {{ nameApp }}, Para tener acceso al <b>Monitoreo</b> de tus proyectos, debes
      verificar tú identidad mediante un link enviado a tú correo electrónico registrado en la
      plataforma
    </h4>
    <h5 v-if="currentUser" class="text-center my-4"><b>Correo:</b> {{ currentUser.email }}</h5>

    <div class="d-flex flex-column flex-md-row justify-content-around w-50 mx-auto mt-4">
      <b-button
        @click="actionResendEmailVerification"
        class="mb-3"
        :class="{
          'btn-multiple-state btn-shadow': true
        }"
        :disabled="loading"
        variant="primary"
        size="md"
      >
        <b-spinner v-if="loading" class="mx-2" small></b-spinner>
        <span class="label">{{
          loading ? 'Enviando..' : $t('user.resend-email-verification')
        }}</span>
      </b-button>
      <b-button class="mb-3" @click="logout" variant="outline-danger" size="md">
        <span class="label">{{ $t('user.logout') }}</span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { nameApp } from '@/constants/config'

export default {
  data() {
    return {
      nameApp,
      loading: false,
      logoFluxsolar: require('@/assets/images/app/logos/logo_fluxsolar.png')
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser'
    })
  },
  methods: {
    ...mapActions({
      signOut: 'user/signOut',
      resendEmailVerification: 'user/resendEmailVerification'
    }),
    logout() {
      this.signOut().then(() => {
        this.$router.push('/login')
      })
    },
    async actionResendEmailVerification() {
      this.loading = true
      await this.resendEmailVerification({ user_id: this.currentUser.id })
      this.loading = false
    }
  }
}
</script>

<style></style>
