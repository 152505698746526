import { ThemeColors } from '../utils'
const colors = ThemeColors()

export const unifiedNetworkData = {
  labels: [],
  datasets: [
    {
      label: 'Red',
      data: [],
      borderColor: 'rgb(255, 162, 57)',
      pointBackgroundColor: 'rgb(255, 162, 57)',
      pointBorderColor: 'rgb(255, 162, 57)',
      pointHoverBackgroundColor: 'rgb(255, 162, 57)',
      pointHoverBorderColor: 'rgb(255, 162, 57)',
      pointRadius: 2,
      pointStyle: 'rectRounded',
      pointBorderWidth: 0.5,
      pointHoverRadius: 2,
      tension: 0.4,
      radius: 2,
      fill: false
    }
  ]
}

export const consumptionBatteryData = {
  labels: [],
  datasets: [
    {
      label: 'Consumo de Bateria',
      data: [],
      borderColor: 'rgb(0, 150, 136)',
      pointBackgroundColor: 'rgb(0, 150, 136)',
      pointBorderColor: 'rgb(0, 150, 136)',
      pointHoverBackgroundColor: 'rgb(0, 150, 136)',
      pointHoverBorderColor: 'rgb(0, 150, 136)',
      pointRadius: 2,
      pointStyle: 'rectRounded',
      pointBorderWidth: 0.5,
      pointHoverRadius: 2,
      tension: 0.4,
      radius: 2,
      fill: false
    }
  ]
}

export const batteryData = {
  labels: [],
  datasets: [
    {
      label: 'Carga de Bateria',
      data: [],
      borderColor: 'rgb(3, 119, 117)',
      pointBackgroundColor: 'rgb(3, 119, 117)',
      pointBorderColor: 'rgb(3, 119, 117)',
      pointHoverBackgroundColor: 'rgb(3, 119, 117)',
      pointHoverBorderColor: 'rgb(3, 119, 117)',
      pointRadius: 2,
      pointStyle: 'rectRounded',
      pointBorderWidth: 0.5,
      pointHoverRadius: 2,
      tension: 0.4,
      radius: 2,
      fill: false
    }
  ]
}
export const energyEfficiencyData = {
  labels: [],
  datasets: [
    {
      label: 'PV',
      data: [],
      borderColor: 'rgb(255, 162, 57, 0.3)',
      pointBackgroundColor: 'rgb(255, 162, 57)',
      pointBorderColor: 'rgb(255, 162, 57)',
      pointHoverBackgroundColor: 'rgb(255, 162, 57)',
      pointHoverBorderColor: 'rgb(255, 162, 57)',
      color: 'rgb(255, 162, 57)',
      pointRadius: 2,
      pointBorderWidth: 1,
      pointHoverRadius: 4,
      tension: 0.4,
      radius: 2,
      fill: true,
      backgroundColor: 'rgba(255, 162, 57, 0.2)'
    },
    {
      label: 'Consumo',
      data: [],
      borderColor: 'rgb(128,128,128, 0.3)',
      pointBackgroundColor: 'rgb(128,128,128)',
      pointBorderColor: 'rgb(128,128,128)',
      pointHoverBackgroundColor: 'rgb(128,128,128)',
      pointHoverBorderColor: 'rgb(128,128,128)',
      pointRadius: 2,
      pointBorderWidth: 1,
      tension: 0.4,
      pointHoverRadius: 4,
      radius: 2,
      fill: true,
      backgroundColor: 'rgba(128,128,128, 0.2)'
    },
    {
      label: 'Bateria',
      data: [],
      borderColor: 'rgb(22,241,172, 0.3)',
      pointBackgroundColor: 'rgb(22,241,172)',
      pointBorderColor: 'rgb(22,241,172)',
      pointHoverBackgroundColor: 'rgb(22,241,172)',
      pointHoverBorderColor: 'rgb(22,241,172)',
      pointRadius: 2,
      pointBorderWidth: 1,
      tension: 0.4,
      pointHoverRadius: 4,
      radius: 2,
      fill: true,
      backgroundColor: 'rgba(22,241,172, 0.2)'
    }
  ]
}

export const energyStatisticsData = {
  labels: [],
  datasets: [
    {
      label: 'Energia Comprada',
      data: [],
      borderColor: 'rgb(128,128,128, 0.3)',
      pointBackgroundColor: 'rgb(128,128,128)',
      pointBorderColor: 'rgb(128,128,128)',
      pointHoverBackgroundColor: 'rgb(128,128,128)',
      pointHoverBorderColor: 'rgb(128,128,128)',
      pointRadius: 2,
      pointBorderWidth: 1,
      tension: 0.4,
      pointHoverRadius: 4,
      radius: 2,
      fill: true,
      backgroundColor: 'rgba(128,128,128, 0.2)'
    },
    {
      label: 'Energía Vendida',
      data: [],
      borderColor: 'rgb(255, 162, 57, 0.3)',
      pointBackgroundColor: 'rgb(255, 162, 57)',
      pointBorderColor: 'rgb(255, 162, 57)',
      pointHoverBackgroundColor: 'rgb(255, 162, 57)',
      pointHoverBorderColor: 'rgb(255, 162, 57)',
      color: 'rgb(255, 162, 57)',
      pointRadius: 2,
      pointBorderWidth: 1,
      pointHoverRadius: 4,
      tension: 0.4,
      radius: 2,
      fill: true,
      backgroundColor: 'rgba(255, 162, 57, 0.2)'
    }
  ]
}

export const energyEfficiencyBarData = {
  labels: [],
  datasets: [
    {
      label: 'PV',
      borderColor: 'rgb(255, 162, 57)',
      backgroundColor: 'rgb(255, 162, 57)',
      data: [456, 479, 324, 569, 702, 600, 670, 800],
      borderWidth: 1
    },
    {
      label: 'Consumo',
      borderColor: 'rgb(247,247,247, 0.3)',
      backgroundColor: 'rgb(247,247,247)',
      data: [364, 504, 605, 400, 345, 320, 325, 310],
      borderWidth: 1
    },
    {
      label: 'Autoconsumo',
      borderColor: 'rgb(22,241,172, 0.3)',
      backgroundColor: 'rgb(22,241,172)',
      data: [364, 504, 605, 400, 345, 320, 325, 310],
      borderWidth: 1
    }
  ]
}

export const areaChartData = {
  labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      label: '',
      data: [54, 63, 60, 65, 60, 68, 60],
      borderColor: colors.themeColor1,
      pointBackgroundColor: colors.foregroundColor,
      pointBorderColor: colors.themeColor1,
      pointHoverBackgroundColor: colors.themeColor1,
      pointHoverBorderColor: colors.foregroundColor,
      pointRadius: 4,
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      fill: true,
      borderWidth: 2,
      backgroundColor: colors.themeColor1_10
    }
  ]
}

export const barChartData = {
  labels: [
    '2021-06-25',
    '2021-06-26',
    '2021-06-27',
    '2021-06-28',
    '2021-06-29',
    '2021-06-30',
    '2021-07-01',
    '2021-07-02'
  ],
  datasets: [
    {
      label: 'PV',
      borderColor: 'rgb(255, 162, 57)',
      backgroundColor: 'rgb(255, 162, 57)',
      data: [456, 479, 324, 569, 702, 600, 670, 800],
      borderWidth: 1
    },
    {
      label: 'Consumo',
      borderColor: 'black',
      backgroundColor: 'black',
      data: [364, 504, 605, 400, 345, 320, 325, 310],
      borderWidth: 1
    }
  ]
}
