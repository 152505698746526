<template>
  <div class="container-energy">
    <b-img
      height="400"
      width="430"
      :src="getImageEnergyIfGeneratorIsActive.img"
      fluid
      alt="Responsive image"
    ></b-img>
    <div class="top">{{ getCalculeKw(pvValue) }}</div>
    <div class="centered" v-if="consumption !== null">{{ getCalculeKw(consumption) }}</div>
    <div class="bottom-right" v-if="network !== null">
      {{ getCalculeKw(Math.abs(network)) }}
    </div>

    <div
      v-show="getImageEnergyIfGeneratorIsActive.generator"
      v-if="battery !== null || generator !== null"
      class="bottom-left"
    >
      {{ getCalculeKw(brandIsGoodwe ? battery : generator) }}
    </div>
    <i
      v-show="ifPvGreatherThanComsumption && pvValue !== 0 && network !== 0"
      class="iconsmind iconsminds-arrow-to arrow-big arrow-diagonal flicker-row"
    ></i>
    <i
      v-show="!ifPvGreatherThanComsumption && network !== 0 && network !== null"
      class="iconsmind iconsminds-up-3 arrow arrow-left move-diagonal-left"
    ></i>
    <i
      v-if="
        (getImageEnergyIfGeneratorIsActive.generator || !brandIsGoodwe) &&
        battery &&
        battery < 0 &&
        battery !== null
      "
      class="iconsmind iconsminds-up-3 arrow arrow-right move-diagonal-right-invested"
    ></i>

    <i
      v-if="
        (getImageEnergyIfGeneratorIsActive.generator || !brandIsGoodwe) &&
        battery &&
        battery > 0 &&
        battery !== null
      "
      class="iconsmind iconsminds-up-3 arrow arrow-right move-diagonal-right"
    ></i>

    <i
      v-if="pvValue !== 0"
      class="iconsmind iconsminds-down-3 arrow arrow-dowm move-vertical"
    ></i>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      imgWithGenerator: require('@/assets/images/app/home/actual_energy.png'),
      imgWithoutGenerator: require('@/assets/images/app/home/flujo_consumo_pv_red.png'),
      imgBattery: require('@/assets/images/app/home/flujo_bateria.png')
    }
  },
  props: {
    consumption: {
      type: Number,
      default: 0.0
    },
    generator: {
      type: Number,
      default: 0.0
    },
    network: {
      type: Number,
      default: 0.0
    },
    pvValue: {
      type: Number,
      default: 0.0
    },
    brand: {
      type: String,
      default: null
    },
    battery: {
      type: Number,
      default: 0.0
    },
    powerPlantActive: {
      default: null
    }
  },
  methods: {
    ...mapActions({ updatePowerPlant: 'project/updatePowerPlant' }),
    getCalculeKw(val) {
      return parseFloat(Math.abs(val) / 1000).toFixed(2) + ' ' + 'kW'
    }
  },
  computed: {
    brandIsGoodwe() {
      return this.brand == 'goodwe' || this.brand == 'Goodwe'
    },
    getImageEnergyIfGeneratorIsActive() {
      if (!this.powerPlantActive) {
        return { img: this.imgWithoutGenerator, generator: false }
      }
      if (this.brandIsGoodwe) {
        return { img: this.imgBattery, generator: true }
      }
      return this.powerPlantActive.is_active
        ? { img: this.imgWithGenerator, generator: true }
        : { img: this.imgWithoutGenerator, generator: false }
    },
    //Si el PV es mayor al consumo, mostrar flecha de inyeccion
    ifPvGreatherThanComsumption() {
      return this.network > 0 && this.network !== null
    }
  }
}
</script>

<style>
.container-energy {
  position: relative;
  text-align: center;
}

.container-energy div {
  font-weight: bold;
}

.top {
  color: white;
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered {
  color: white;
  position: absolute;
  top: 64.7%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom-right {
  color: white;
  position: absolute;
  top: 81.7%;
  right: 17%;
  transform: translate(50%, -20%);
}

.bottom-left {
  color: white;
  position: absolute;
  top: 81.7%;
  left: 0%;
  right: 0;
  transform: translate(-31%, -20%);
}

@media only screen and (min-width: 2000px) {
  .bottom-right {
    right: 27%;
    transform: translate(50%, 0%) !important;
  }
  .bottom-left {
    right: -90%;
  }

  .arrow-big {
    font-size: 5rem;
  }
  .arrow-diagonal {
    top: 21%;
    left: 55.1% !important;
    transform: rotate(45deg);
  }
}

@media only screen and (max-width: 1150px) {
  .arrow-big {
    font-size: 3.8rem !important;
  }
  .arrow-diagonal {
    top: 21%;
    left: 55.1% !important;
    transform: rotate(45deg);
  }
}

@media only screen and (max-width: 950px) {
  .arrow-big {
    font-size: 3.2rem !important;
  }
  .arrow-diagonal {
    top: 21%;
    left: 55.1% !important;
    transform: rotate(45deg);
  }
}

@media only screen and (max-width: 771px) {
  .bottom-right {
    transform: translate(50%, -20%);
    right: 28%;
  }
  .bottom-left {
    transform: translate(-20%, -20%);
  }

  .arrow-big {
    font-size: 5rem !important;
  }
  .arrow-diagonal {
    top: 21%;
    left: 55.1% !important;
    transform: rotate(45deg);
  }
}

@media only screen and (max-width: 640px) {
  .bottom-right {
    transform: translate(120%, -20%);
    right: 28%;
  }
  .bottom-left {
    transform: translate(-25%, -20%);
  }
}

@media only screen and (max-width: 590px) {
  .bottom-right {
    transform: translate(180%, -20%);
  }
  .bottom-left {
    transform: translate(-30%, -20%);
  }
}

.arrow {
  font-size: 1.5rem;
  border: 5px orange;
  color: orange;
  position: absolute;
}
.arrow-big {
  font-size: 4.5rem;
  border: 8px orange;
  color: orange;
  position: absolute;
}

.arrow-diagonal {
  top: 21%;
  left: 57.1%;
  transform: rotate(45deg);
}

.arrow-left {
  top: 61%;
  left: 57.1%;
  transform: rotate(-45deg);
}

.arrow-right {
  top: 60.7%;
  left: 29.1%;
  transform: rotate(45deg);
}

.arrow-right-invested {
  top: 60.7%;
  left: 29.1%;
  transform: rotate(90deg);
}

.arrow-dowm {
  top: 34.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Animaciones */
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.flicker-row {
  -webkit-animation: flickerAnimation 1.3s infinite;
  -moz-animation: flickerAnimation 1.3s infinite;
  -o-animation: flickerAnimation 1.3s infinite;
  animation: flickerAnimation 1.3s infinite;
}
.move-vertical {
  animation-name: move-vertical;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: move-vertical;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes move-vertical {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -32%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@-webkit-keyframes move-vertical {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -32%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes move-vertical {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -32%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.move-diagonal-right-invested {
  animation-name: move-diagonal-right-invested;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: move-diagonal-right-invested;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes move-diagonal-right-invested {
  0% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(220deg);
  }
  50% {
    opacity: 0;
    transform: translate(3px, -10%) translate(3px) rotate(220deg);
  }
  100% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(220deg);
  }
}

@-webkit-keyframes move-diagonal-right-invested {
  0% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(220deg);
  }
  50% {
    opacity: 0;
    transform: translate(3px, -10%) translate(3px) rotate(220deg);
  }
  100% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(220deg);
  }
}

@keyframes move-diagonal-right-invested {
  0% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(220deg);
  }
  50% {
    opacity: 0;
    transform: translate(3px, -10%) translate(3px) rotate(220deg);
  }
  100% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(220deg);
  }
}

.move-diagonal-right {
  animation-name: move-diagonal-right;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: move-diagonal-right;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes move-diagonal-right {
  0% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(45deg);
  }
  50% {
    opacity: 0;
    transform: translate(3px, -10%) translate(3px) rotate(45deg);
  }
  100% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(45deg);
  }
}

@-webkit-keyframes move-diagonal-right {
  0% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(45deg);
  }
  50% {
    opacity: 0;
    transform: translate(3px, -10%) translate(3px) rotate(45deg);
  }
  100% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(45deg);
  }
}

@keyframes move-diagonal-right {
  0% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(45deg);
  }
  50% {
    opacity: 0;
    transform: translate(3px, -10%) translate(3px) rotate(45deg);
  }
  100% {
    opacity: 1;
    transform: translate(-20px) translate(20px) rotate(45deg);
  }
}

.move-diagonal-left {
  animation-name: move-diagonal-left;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: move-diagonal-left;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes move-diagonal-left {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -32%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@-webkit-keyframes move-diagonal-left {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -32%) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes move-diagonal-left {
  0% {
    opacity: 1;
    transform: translate(20px) translate(4px) rotate(-45deg);
  }
  50% {
    opacity: 0;
    transform: translate(12px, -15%) translate(3px) rotate(-45deg);
  }
  100% {
    opacity: 1;
    transform: translate(20px) translate(3px) rotate(-45deg);
  }
}
</style>
