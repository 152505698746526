<template>
  <div :class="containerClass">
    <canvas height="300px" ref="chart"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js'
import { barChartOptions2 } from './config'

export default {
  props: {
    data: {
      type: Object
    },
    containerClass: {
      type: String,
      default: 'chart-container'
    },
    shadow: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.shadow) {
      Chart.defaults.global.datasets.barWithShadow = Chart.defaults.global.datasets.bar
      Chart.defaults.barWithShadow = Chart.defaults.bar
      Chart.controllers.barWithShadow = Chart.controllers.bar.extend({
        draw(ease) {
          Chart.controllers.bar.prototype.draw.call(this, ease)
          const chartCtx = this.chart.ctx
          chartCtx.save()
          chartCtx.shadowColor = 'rgba(0,0,0,0.2)'
          chartCtx.shadowBlur = 7
          chartCtx.shadowOffsetX = 5
          chartCtx.shadowOffsetY = 7
          chartCtx.responsive = true
          Chart.controllers.bar.prototype.draw.apply(this, arguments)
          chartCtx.restore()
        }
      })
    }
    const ctx = this.$refs.chart
    const myChart = new Chart(ctx, {
      type: this.shadow ? 'barWithShadow' : 'bar',
      data: this.data,
      options: barChartOptions2
    })
    this.chartCtx = myChart
  },
  watch: {
    'data.labels': {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.chartCtx.update()
        }, 500)
      }
    }
  }
}
</script>
