<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h6 class="">{{ $t('general.energy_efficiency') }}</h6>
    </div>
    <bar-chart
      v-if="interval !== 'dayMonth' && dataChartBar.labels.length"
      :data="dataChartBar"
      shadow
    />
    <line-chart
      v-else-if="interval === 'dayMonth' && dataChart.labels.length"
      :data="dataChart"
      shadow
    />
    <p v-else class="text-center mt-5">Sin datos para mostrar</p>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/Line'
import BarChart from '@/components/Charts/BarWithotStacked'
import EventBus from '@/bus'

export default {
  props: {
    dataChart: {
      defautlt: () => []
    },
    dataChartBar: {
      defautlt: () => []
    }
  },
  created() {
    EventBus.$on('changeDataCharts', ({ data, interval, id }) => {
      this.id = id
      this.interval = interval
      this.setDataChart(interval, data)
    })
  },
  data() {
    return {
      interval: null,
      //Data seleccionada de Proyecto, inversor, central
      id: null
    }
  },
  components: {
    LineChart,
    BarChart
  },
  methods: {
    //Añadir Data a la grafica
    async setDataChart(interval, data) {
      this.dataChart.labels = []
      this.dataChartBar.labels = []
      //Por ahora solo para cuando se selecciona un inversor
      this.dataChart.labels = data.map((item) => `${this.$moment(item.date).format('HH:mm')}`)
      this.dataChartBar.labels = data.map((item) =>
        interval == 'day'
          ? `${this.$moment(item.date).format('DD')}`
          : interval == 'month'
          ? `${this.$moment(item.date).format('MMM')}`
          : interval == 'range'
          ? `${this.$moment(item.date).format('DD/MM/YYYY')}`
          : `${this.$moment(item.date).format('YYYY')}`
      )
      //Calculate kw data
      let dataFormatted = interval == 'dayMonth' ? this.calculateKwData(data) : data
      if (this.interval === 'dayMonth') {
        this.dataChart.datasets[0].data = dataFormatted.map((item) => item.pv)
        this.dataChart.datasets[1].data = dataFormatted.map((item) => item.load)
        this.dataChart.datasets[2].data = dataFormatted.map((item) => item.battery)
      } else {
        this.dataChartBar.datasets[0].data = dataFormatted.map((item) => item.pv)
        this.dataChartBar.datasets[1].data = dataFormatted.map((item) => item.load)
        this.dataChartBar.datasets[2].data = dataFormatted.map((item) => item.selfuse)
      }
    },
    calculateKwData(data) {
      return data.map((item) => {
        return {
          ...item,
          pv: parseFloat(item.pv / 1000).toFixed(2),
          load: parseFloat(item.load / 1000).toFixed(2),
          battery: parseFloat(item.battery / 1000).toFixed(2)
        }
      })
    }
  }
}
</script>

<style></style>
