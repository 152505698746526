<template>
  <b-card class="">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="">Tiempo Climático</h6>
    </div>
    <b-row class="d-flex justify-content-center align-items-center">
      <b-colxx
        class="d-flex justify-content-center text-center"
        v-for="w in weather"
        :key="w.date"
        lg="4"
        md="4"
        sm="8"
        xxs="8"
      >
        <div>
          <b-img height="100" width="100" :src="w.icon"></b-img>
          <p class="card-text font-weight-bold text- mb-0">
            {{ w.text }}
          </p>
          <small>{{ `Min: ${w.min} ºC / Max: ${w.max} ºC` }}</small>
          <p class="card-text font-weight-semibold text-center">
            <b-badge variant="warning">{{ w.date }}</b-badge>
          </p>
        </div>
      </b-colxx>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    weather: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style></style>
