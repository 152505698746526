<template>
  <Layout>
    <PageHeader />
    <div class="p-3 pt-0">
      <b-row>
        <b-colxx xxs="12">
          <h1>{{ $t('menu.monitoring') }}</h1>
          <div class="separator mb-4"></div>
        </b-colxx>
      </b-row>
      <section v-if="currentUser && currentUser.verified">
        <b-row class="mb-3">
          <!-- Etiquetas -->
          <b-colxx lg="4" md="4" sm="6" xxs="12">
            <label>{{ $t('menu.tag') }}</label>
            <v-select
              class="w-100 mt-1 select__custom"
              :reduce="(tag) => tag.id"
              :placeholder="`${$t('todo.without')} ${$t('menu.tag')}`"
              :options="tagsUserAuth"
              v-model="modules.tag"
              label="name"
            >
            </v-select>
          </b-colxx>
          <!-- Proyectos -->
          <b-colxx lg="4" md="4" sm="6" xxs="12">
            <label>Proyecto</label>
            <v-select
              class="w-100 mt-1 select__custom"
              :reduce="(project) => project.project"
              placeholder="Todos los proyectos"
              :options="tagProjects"
              v-model="modules.project"
              label="name"
            >
            </v-select>
          </b-colxx>
          <b-colxx lg="4" md="4" sm="6" xxs="12">
            <label>Central</label>
            <v-select
              class="w-100 mt-1 select__custom"
              :reduce="(powerPlant) => powerPlant.id"
              @change="changeConsumptionModule($event)"
              placeholder="Todas las centrales"
              :options="powerPlants"
              v-model="modules.powerplant"
              label="name"
            >
            </v-select>
          </b-colxx>
        </b-row>
        <b-row align-v="start">
          <b-overlay :show="loading" rounded="md" spinner-variant="warning">
            <b-colxx xxs="12" v-if="modules.powerplant">
              <!-- AQUI QUITAR EL FALSE -->
              <b-row align-v="start" class="mb-4">
                <b-colxx xxs="12" lg="4" md="4" sm="12">
                  <b-card class="mb-4">
                    <h4 class="card-title">
                      {{ $t('general.current_power') }}
                    </h4>
                    <p class="text-muted text-custom" v-if="consumption">
                      Última actualización:
                      {{ consumption.power.date | moment('DD/MMM/YYYY HH:mm') }} hrs.
                    </p>
                    <chart-consumption
                      :pv-value="consumption ? Number(consumption.power.pv) : 0"
                      :generator="
                        consumption &&
                        consumption.power &&
                        consumption.power.battery_charge !== null
                          ? consumption.power.battery
                          : null
                      "
                      :network="consumption && consumption.power ? consumption.power.grid : 0"
                      :consumption="
                        consumption && consumption.power ? consumption.power.load : 0
                      "
                      :powerPlantActive="powerPlantActive"
                      brand="goodwe"
                      :battery="
                        consumption &&
                        consumption.power &&
                        consumption.power.battery_charge !== null
                          ? consumption.power.battery
                          : null
                      "
                    ></chart-consumption>
                  </b-card>
                </b-colxx>
                <b-colxx xxs="12" lg="4" md="4" sm="12">
                  <b-row>
                    <b-col cols="12" xxs="12" lg="12" md="12" sm="12">
                      <b-card class="" v-if="currentPowerPlantHaveBattery">
                        <h4 class="card-title" style="margin-bottom: -0.5rem">
                          {{ $t('general.battery') }}
                        </h4>
                        <!-- Chart -->
                        <apexchart
                          class="apex-charts"
                          type="radialBar"
                          height="200"
                          dir="ltr"
                          :series="
                            consumption ? [Number(consumption.power.battery_charge)] : [0]
                          "
                          :options="chartRadarOptions"
                        ></apexchart>
                      </b-card>
                      <b-card class="mb-3">
                        <h4 class="card-title mb-3">
                          {{ $t('general.generation') }}
                        </h4>
                        <b-colxx lg="12" md="12" sm="12" xxs="12" class="mb-2">
                          <div class="table-responsive mt-3">
                            <table class="table table-centered">
                              <tbody>
                                <tr>
                                  <td style="width: 140px">
                                    <p class="mb-0">{{ $t('interval.today') }}</p>
                                  </td>
                                  <td style="width: 120px">
                                    <h5 class="mb-0">
                                      {{
                                        consumption
                                          ? getCalculateValue(
                                              consumption.power.generation_daily
                                            )
                                          : '0 kWh'
                                      }}
                                    </h5>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p class="mb-0">Total</p>
                                  </td>
                                  <td>
                                    <h5 class="mb-0">
                                      {{
                                        consumption
                                          ? getCalculateValue(
                                              consumption.power.generation_total,
                                              'MWh'
                                            )
                                          : '0 MWh'
                                      }}
                                    </h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-colxx>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-colxx>
                <b-colxx xxs="12" lg="4" md="4" sm="12">
                  <b-card class="">
                    <div class="d-flex align-items-start">
                      <h4 class="card-title">
                        {{ $t('general.impact_environment_total') }}
                      </h4>
                    </div>
                    <div class="row" v-if="consumption">
                      <div class="col-sm-12 col-md-6">
                        <div class="social-source text-center mt-3">
                          <div class="avatar-xs mx-auto mb-2">
                            <span class="avatar-title rounded-circle bg-warning font-size-16">
                              <i class="mdi mdi-tree text-white"></i>
                            </span>
                          </div>
                          <h5 class="font-size-15">{{ $t('general.tree') }}</h5>
                          <p class="text-muted my-0">
                            {{
                              consumption && consumption.ambiental
                                ? new Intl.NumberFormat('de-DE').format(
                                    parseInt(consumption.ambiental.tree)
                                  )
                                : 0
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="social-source text-center mt-3">
                          <div class="avatar-xs mx-auto mb-2">
                            <span class="avatar-title rounded-circle bg-warning font-size-16">
                              <i class="mdi mdi-car text-white"></i>
                            </span>
                          </div>
                          <h5 class="font-size-15">Ton CO2</h5>
                          <p class="text-muted my-0">
                            {{
                              consumption && consumption.ambiental.co2
                                ? consumption.ambiental.co2
                                : 0
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center mt-2">
                      <div class="col-sm-6 text-center">
                        <span style="font-size: 11px; color: white">
                          (Desde la instalación del equipo)
                        </span>
                      </div>
                      <div class="col-sm-6 text-center">
                        <span style="font-size: 11px; color: white" class="mb-0">
                          (Durante {{ $moment().format('YYYY') }})
                        </span>
                      </div>
                    </div>
                  </b-card>
                  <b-card class="mb-3" v-if="consumption">
                    <h4 class="card-title mb-3">
                      {{ $t('general.current_economic_impact') }}
                    </h4>
                    <b-colxx lg="12" md="12" sm="12" xxs="12" class="mb-2">
                      <div class="table-responsive mt-3">
                        <table class="table table-centered">
                          <tbody>
                            <tr>
                              <td style="width: 140px">
                                <p class="mb-0">{{ $t('interval.today') }}</p>
                              </td>
                              <td style="width: 120px">
                                <h5 class="mb-0">
                                  {{
                                    `$ ${new Intl.NumberFormat('de-DE').format(
                                      parseInt(consumption.economic.day)
                                    )}`
                                  }}
                                </h5>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p class="mb-0">Total</p>
                              </td>
                              <td>
                                <h5 class="mb-0">
                                  {{
                                    `$ ${new Intl.NumberFormat('de-DE').format(
                                      parseInt(consumption.economic.total)
                                    )}`
                                  }}
                                </h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-colxx>
                  </b-card>
                </b-colxx>
              </b-row>
            </b-colxx>
            <p class="ml-3" v-else style="font-size: 11px; color: gray">
              Por favor seleccione un proyecto
            </p>
          </b-overlay>
          <b-colxx xxs="12" v-show="modules.powerplant">
            <b-card class="mb-4">
              <selector-range></selector-range>
            </b-card>
          </b-colxx>

          <!-- Grafica de Rendimiento energetico -->
          <b-colxx xxs="12" v-show="modules.powerplant">
            <b-card class="mb-4">
              <energy-efficiency-chart
                :data-chart="energyEfficiencyData"
                :data-chart-bar="energyEfficiencyBarData"
              ></energy-efficiency-chart>
            </b-card>
          </b-colxx>

          <!-- Grafica de Estadisticas de energía -->
          <b-colxx xxs="12" v-show="modules.powerplant">
            <b-card class="mb-4">
              <energy-statistics-chart
                :data-chart="energyStatisticsData"
              ></energy-statistics-chart>
            </b-card>
          </b-colxx>

          <!-- Grafica de Red -->
          <!-- <b-colxx lg="12" md="12" xxs="12" v-if="modules.powerplant" class="mt-4">
            <b-card class="">
              <b-row>
                <b-colxx lg="12" md="12" sm="12" xxs="12">
                  <unified-network :data-chart="unifiedNetworkData"></unified-network>
                </b-colxx>
              </b-row>
              <b-row> </b-row>
            </b-card>
          </b-colxx> -->

          <!-- Grafica de consumo de bateria -->
          <!-- <b-colxx
            lg="12"
            md="12"
            xxs="12"
            v-if="modules.powerplant && consumption && consumption.station_type == brandGoodwe"
            class="mt-4"
          >
            <b-card class="">
              <b-row>
                <b-colxx lg="12" md="12" sm="12" xxs="12">
                  <consumption-battery
                    :data-chart="consumptionBatteryData"
                  ></consumption-battery>
                </b-colxx>
              </b-row>
              <b-row> </b-row>
            </b-card>
          </b-colxx>
 -->
          <!-- Grafica de carga de bateria -->
          <!-- <b-colxx
            lg="12"
            md="12"
            xxs="12"
            v-if="modules.powerplant && consumption && consumption.station_type == brandGoodwe"
            class="mt-4"
          >
            <b-card class="">
              <b-row>
                <b-colxx lg="12" md="12" sm="12" xxs="12">
                  <charge-battery :data-chart="batteryData"></charge-battery>
                </b-colxx>
              </b-row>
              <b-row> </b-row>
            </b-card>
          </b-colxx> -->

          <!-- <b-colxx xxs="12" v-show="modules.powerplant">
            <b-card class="my-4">
              <self-consumption-chart :data-chart="barChartData"></self-consumption-chart>
            </b-card>
          </b-colxx>
 -->
          <b-colxx lg="6" md="6" xxs="12" v-show="modules.powerplant" class="mt-4">
            <powerplant-weather :weather="weather"></powerplant-weather>
          </b-colxx>

          <b-colxx lg="6" md="6" xxs="12" v-show="modules.powerplant" class="mt-4">
            <b-card class="">
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="">{{ $t('menuitems.pages.location') }}</h6>
              </div>
              <b-row>
                <b-colxx lg="12" md="12" sm="12" xxs="12">
                  <map-marker
                    :text-input="null"
                    :positionObj.sync="positionMarkerShowPowerPlantMap"
                    :only-show="true"
                  ></map-marker>
                </b-colxx>
              </b-row>
              <b-row> </b-row>
            </b-card>
          </b-colxx>
        </b-row>
      </section>
      <div v-else>
        <not-verify-account></not-verify-account>
      </div>
    </div>
  </Layout>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Layout from '../../layouts/main'
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import ChartConsumption from '@/components/Charts/Monitoring/Consumption'

import EnergyEfficiencyChart from '@/components/Charts/Monitoring/EnergyEfficiency.vue'
import EnergyStatisticsChart from '@/components/Charts/Monitoring/EnergyStatistics.vue'
// import SelfConsumptionChart from '@/components/Charts/Monitoring/SelfConsumption.vue'
// import UnifiedNetwork from '@/components/Charts/Monitoring/UnifiedNetwork.vue'
// import ConsumptionBattery from '@/components/Charts/Monitoring/ConsumptionBattery.vue'
// import ChargeBattery from '@/components/Charts/Monitoring/ChargeBattery.vue'

import PowerplantWeather from '@/components/Custom/powerPlant/Weather.vue'
import {
  barChartData,
  energyEfficiencyBarData,
  energyEfficiencyData,
  energyStatisticsData,
  unifiedNetworkData,
  consumptionBatteryData,
  batteryData
} from '@/data/charts'
import { chartRadarOptions } from '@/constants/chartOptions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EventBus from '@/bus'
import MapMarker from '@/components/GoogleMaps/MapMarker.vue'
import NotVerifyAccount from '@/router/views/account/NotVerifyAccount.vue'
import SelectorRange from '@/components/Charts/Monitoring/SelectorRange.vue'
import { coords_santiago } from '@/constants/config'

/**
 * Monitoring Component
 */
export default {
  page: {
    title: 'Monitoreo',
    meta: [
      {
        name: 'description',
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    'v-select': vSelect,
    PageHeader,
    ChartConsumption,
    EnergyEfficiencyChart,
    EnergyStatisticsChart,
    // SelfConsumptionChart,
    // ConsumptionBattery,
    // ChargeBattery,
    // UnifiedNetwork,

    MapMarker,
    NotVerifyAccount,
    SelectorRange,
    PowerplantWeather
  },
  data() {
    return {
      title: 'Monitoreo',

      //Monitoreo
      //Valores booleanos para mostrar bateria
      showBatteryConsumptionChart: false,
      showBatteryChargeChart: false,

      //Marcas
      brandGoodwe: 'goodwe',
      //Intervalo de tiempo de actualizacion de los datos
      intervalUpdateSeconds: 30000,
      intervalUpdateMonitor: null,

      //Chart Data
      barChartData,
      energyEfficiencyData,
      energyStatisticsData,
      energyEfficiencyBarData,
      unifiedNetworkData,
      consumptionBatteryData,
      batteryData,
      chartRadarOptions,

      projectActive: {},
      powerPlantActive: {},
      modules: {
        tag: null,
        project: null,
        powerplant: null
      },
      loading: false,
      week: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
      weather: [],
      auxDay: 0
    }
  },
  created() {
    clearInterval(this.intervalUpdateMonitor)
    // Endpoint de actualizar
    this.updateMonitoringDataIntervalTime()
  },
  computed: {
    ...mapGetters({
      projects: 'project/projects',
      tagsUserAuth: 'tag/tagsUserAuth',
      tagProjects: 'tag/tagProjects',
      powerPlants: 'project/powerPlants',
      consumption: 'monitoring/consumption',
      currentUser: 'user/currentUser',
      powerPlant: 'project/powerPlant',
      consumptionChart: 'monitoring/consumptionChart'
    }),
    latestInvestorRegister() {
      if (this.consumptionChart.length) {
        return this.consumptionChart.at(-1)
      }
      return null
    },
    getImageCurrentProject() {
      return (
        this.projectActive?.project_image ||
        require('@/assets/images/app/projects/project_default.jpeg')
      )
    },
    showTable() {
      return !this.modules.powerplant || !this.modules.project || !this.modules.investor
        ? false
        : true
    },
    currentPowerPlantHaveBattery() {
      return this.powerPlant && this.powerPlant.central_battery
    },
    positionMarkerShowPowerPlantMap() {
      if (this.modules.powerplant) {
        const powerPlant = this.powerPlants.find((item) => item.id == this.modules.powerplant)
        return {
          lat: powerPlant ? Number(powerPlant.commune.latitude) : coords_santiago.lat,
          lng: powerPlant ? Number(powerPlant.commune.length) : coords_santiago.lng
        }
      } else {
        return { lat: -33.447487, lng: -70.673676 }
      }
    }
  },
  methods: {
    ...mapMutations({
      setInvestors: 'project/setInvestors',
      setPowerPlants: 'project/setPowerPlants',
      setTagProjects: 'tag/setTagProjects'
    }),
    ...mapActions({
      getProjectsUserAuth: 'project/getProjectsUserAuth',
      getAllProjects: 'project/getAllProjects',
      getTagProjects: 'tag/getTagProjects',
      getTagsUserAuth: 'tag/getTagsUserAuth',
      getConsumptionModule: 'monitoring/getConsumptionModule',
      getPowerPlants: 'project/getPowerPlants',
      getPowerPlant: 'project/getPowerPlant',
      getPowerPlantWeather: 'project/getPowerPlantWeather'
    }),
    //Actualizar data de monitoreo en intervalo de tiempo constante
    updateMonitoringDataIntervalTime() {
      this.intervalUpdateMonitor = setInterval(() => {
        if (this.modules.powerplant) {
          EventBus.$emit('moduleSelected', {
            id: this.modules.powerplant
          })
          return this.fetchConsumptionModule({
            id: this.modules.powerplant
          })
        }
      }, this.intervalUpdateSeconds)
    },
    //Obtener calculo de kwh del consumo
    getCalculateValue(val, unit = 'kWh') {
      if (val >= 1000) {
        return (
          new Intl.NumberFormat('de-DE').format(parseFloat(val / 1000).toFixed(2)) + ' ' + unit
        )
      }
      return parseFloat(val).toFixed(2) + ' ' + 'kWh'
    },
    async getAllTagProjects(setProject, onlyTagsProjects = false) {
      //Verificacion para traer los proyectos asociados a una etiqueta, o todos los proyectos a los que pertenece el usuario directamente
      const projects = onlyTagsProjects
        ? await this.getTagProjects(onlyTagsProjects)
        : //Si es admin, se solicitan todos los proyectos, en caso contrario solo a los que el usuario pertenezca
        this.currentUser.is_staff
        ? await this.getAllProjects()
        : await this.getProjectsUserAuth()
      if (projects?.length && (setProject || projects.length == 1)) {
        const [project] = projects
        this.modules.project = onlyTagsProjects ? project.project : project.user_project?.id
      }
      if (onlyTagsProjects) {
        this.setTagProjects(projects)
      } else {
        this.setTagProjects(
          projects.map((project) => {
            return {
              project: this.currentUser.is_staff ? project.id : project.user_project.id,
              project_image: this.currentUser.is_staff
                ? project.image_project
                : project.user_project.image_project,
              name: this.currentUser.is_staff ? project.name : project.user_project.name,
              tag: null
            }
          })
        )
      }
    },
    setProjectActive(projectId) {
      const project = this.tagProjects.find((tagProject) => tagProject?.project == projectId)
      this.projectActive = project ? project : {}
    },
    //Modificar data de consumo
    async changeConsumptionModule(id) {
      if (id) {
        this.loading = true
        try {
          await this.fetchConsumptionModule({
            id
          })
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      }
    },
    async fetchConsumptionModule({ id }) {
      this.showBatteryConsumptionChart = false
      this.showBatteryChargeChart = false
      try {
        await this.getConsumptionModule({
          id
        })
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPowerPlantWeather(city) {
      const response = await this.getPowerPlantWeather(city)
      this.setWeatherData(response)
    },
    setWeatherData(data) {
      this.weather = data.forecast.forecastday.map((day) => {
        return {
          date: this.convertDay(day.date),
          icon: day.day.condition.icon,
          text: day.day.condition.text,
          max: day.day.maxtemp_c,
          min: day.day.mintemp_c
        }
      })
      this.auxDay = 0
    },
    convertDay(day) {
      if (this.auxDay === 2) {
        const date = new Date(day)
        const number = date.getDay()
        return this.week[number]
      } else {
        this.auxDay++
        return this.$moment(day).calendar()
      }
    }
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(user) {
        if (user?.verified) {
          this.getTagsUserAuth()
          this.getAllTagProjects(false)
        }
      }
    },
    tagProjects() {
      this.setPowerPlants([])
    },
    'modules.tag': function (newValue) {
      if (newValue) {
        this.getAllTagProjects(true, { tagId: newValue })
      } else {
        this.getAllTagProjects(true)
      }
      this.modules.project = null
      this.modules.powerplant = null
      this.setPowerPlants([])
    },
    'modules.project': async function (newValue) {
      this.setProjectActive(newValue)
      if (newValue) {
        const powerPlants = await this.getPowerPlants({
          projectId: newValue
        })
        // si posee solo una planta se setea por defecto
        if (powerPlants.length == 1) {
          const [powerPlant] = powerPlants
          this.modules.powerplant = powerPlant.id
        }
      } else {
        this.setPowerPlants([])
        this.modules.powerplant = null
      }
    },
    'modules.powerplant': async function (newValue) {
      if (newValue) {
        //Setear la planta actual
        this.powerPlantActive = await this.getPowerPlant({ id: newValue })
        await this.fetchPowerPlantWeather(this.powerPlant?.commune?.city)
        EventBus.$emit('moduleSelected', {
          id: this.modules.powerplant
        })
        // si posee solo un inversor se setea por defecto
        await this.fetchConsumptionModule({
          id: this.modules.powerplant
        })
      } else {
        //Setear la planta actual como null
        this.powerPlantActive = null
      }
    }
  },
  destroyed: function () {
    clearInterval(this.intervalUpdateMonitor)
  }
}
</script>
<style>
.text-info-energy {
  font-size: 13px !important;
  color: white !important;
}
.text-custom {
  color: white !important;
  font-size: 0.6rem !important;
}
</style>
